import React from 'react'
import { Grid } from '@mui/material'
import hero from './image/ok.png'
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1200
});

function Desktop() {
  return (
    <div className=''>
    
      <Grid item lg={12} md={12} xs={12} sm={12} className='moveelement_center '>
     

<img src={hero} style={{width:"70%"}}  className='blend'/>

     </Grid>

  <div className='container'><Grid container spacing={2}>
     <Grid item lg={12} md={12} xs={12} sm={12}>
    <br/> <br/> <br/> 
<h1 className='title popout'style={{ textAlign:"center" }}>We are working on rewinding your movie.</h1>
<br/>
<p className='under_title popout' style={{ textAlign:"center" }}>...but while you wait, check out all that Sling TV has to offer.</p>

<p className='under_title_ca popout' style={{ textAlign:"center" }}>CA : ERKYpXQNHXNJxgWBt9X5CSs4w1SSLWmXMsm1zRCoiqPs</p>
<br/>
<div style={{display:"flex" , textAlign:"center" , justifyContent:"center"}}>
     <a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=ERKYpXQNHXNJxgWBt9X5CSs4w1SSLWmXMsm1zRCoiqPs&fixed=in'>

     <button class='glowing-btn'><span class='glowing-txt'>B<span class='faulty-letter'>U</span>Y</span></button>

     </a>



      </div>
     
      <br/> <br/>
    
     
    <br/> <br/>
          
     </Grid> <br/> <br/> <br/> <br/> <br/> <br/>
   
     </Grid>
      <br/>   <br/></div>
    
    
      </div>
  )
}

export default Desktop