import React from 'react'
import Marquee from "react-fast-marquee";
function Desktopthree() {
  return (
    <div className='popout'>
      <br/><br/><br/><br/>
<Marquee className='first_marque'>
  <br/> <br/>
 <h1>Blockbuster. Simpler Times. Be Kind & Rewind</h1> 
 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;   &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; 
 <h1>Blockbuster. Simpler Times. Be Kind & Rewind</h1> 

 <br/> <br/>
</Marquee>

<Marquee className='two_marque'>
<br/> <br/>
<h1>Blockbuster. Simpler Times. Be Kind & Rewind</h1> 
 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; 
 <h1>Blockbuster. Simpler Times. Be Kind & Rewind</h1> 
 <br/> <br/>
</Marquee>



<br/><br/><br/><br/>
    </div>
  )
}

export default Desktopthree