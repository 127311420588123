import React from 'react'
import m1 from './Meme/m11.jpeg'
import m2 from './Meme/m12.jpeg'
import m3 from './Meme/m13.jpeg'
import m4 from './Meme/m14.jpeg'
import m5 from './Meme/m15.jpeg'
import m6 from './Meme/m16.jpeg'
import m7 from './Meme/m17.jpeg'
import m8 from './Meme/m18.jpeg'
import { Grid } from '@mui/material'
import m9 from './Meme/m19.jpeg'
import m10 from './Meme/m20.jpeg'
function Dex() {
  return (
    <div className='container'>
              <Grid container spacing={2}>
    
<Grid item md={4} lg={4} xs={12} sm={12}>
            <img src={m8} style={{width:"100%"}} className='popout'/>
</Grid>
<Grid item md={4} lg={4} xs={12} sm={12}>
            <img src={m9} style={{width:"100%"}} className='popout'/>
</Grid>
<Grid item md={4} lg={4} xs={12} sm={12}>
            <img src={m2} style={{width:"100%"}} className='popout'/>
</Grid>
<Grid item md={4} lg={4} xs={12} sm={12}>
            <img src={m3} style={{width:"100%"}} className='popout'/>
</Grid>
<Grid item md={4} lg={4} xs={12} sm={12}>
            <img src={m7} style={{width:"100%"}} className='popout'/>
</Grid>
<Grid item md={4} lg={4} xs={12} sm={12}>
            <img src={m4} style={{width:"100%"}} className='popout'/>
</Grid>
<Grid item md={4} lg={4} xs={12} sm={12}>
            <img src={m10} style={{width:"100%"}} className='popout'/>
</Grid>
<Grid item md={4} lg={4} xs={12} sm={12}>
            <img src={m5} style={{width:"100%"}} className='popout'/>
</Grid>
<Grid item md={4} lg={4} xs={12} sm={12}>
            <img src={m6} style={{width:"100%"}} className='popout'/>
</Grid>

          <Grid item md={4} lg={4} xs={12} sm={12}>
            <img src={m1} style={{width:"100%"}} className='popout'/>
</Grid>
          
              </Grid>


    </div>
  )
}

export default Dex