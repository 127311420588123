import React from "react";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Desktop from "./Desktop";
import DesktopM from "./DeskMob";
import Desktopthree from "./Desktopthree";
import Dex from "./Dex";
import Footer from "./Footer";
import Navbar  from "./Navbar";
import Gib from "./Gib";




const Home = () => {


  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));




  return (
    <div className="">


<div className="">
   

 
      {!smallScreen && (
        <div className="bg">
          <div className="pageCon">
            <Navbar/>
        <Desktop/>
        <Desktopthree/>
<Dex/>
        <Desktopthree/>
        <Gib/>
        <Desktopthree/>
        <Footer/>
        </div>
     </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
         <div className="bg">
           <div className="pageCon">
              <Navbar/>
              < DesktopM />
        <Desktopthree/>
        <Dex/>
        <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
        <Desktopthree/>
        <Gib/>
        <Desktopthree/>
         <Footer/>
         </div>

       </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
    </div>
     
     
    </div>
  );
};

export default Home;
