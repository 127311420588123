import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow
} from 'mdb-react-ui-kit';

import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Grid } from '@mui/material'
import hero from './image/blockbus.jpeg'








export default function App() {
  return (
    <MDBFooter bgColor='' className='text-center text-lg-left '>
      <br/>   <br/> <br/>
     

      <div className='text-center p-3 bgf' style={{  }} >     <Grid item lg={12} md={12} xs={12} sm={12} className='moveelement_center'>

<img src={hero} style={{width:"50%"}}  className='popout'/>

     </Grid>
        &copy; {new Date().getFullYear()} Copyright $ Blockbuster
       
      </div>
    </MDBFooter>
  );
}