import React from 'react'
import { Grid } from '@mui/material'
import btc from './image/rady.png'
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import ord from './image/birds.png'
import dex from './image/blockbus.jpeg'
function Navbar() {
  return (
    <div className=''>
<br/>  
     <Grid container spacing={2}>
     <Grid item lg={2.4} md={2.4} xs={12} sm={12} className='moveelement_center popout'>
<a href='' target='_blank'><img src={dex } style={{width:"100px"}}/></a>


     </Grid>
     <Grid item lg={2.4} md={2.4} xs={6} sm={6} className='moveelement_center popout'>
 <a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=ERKYpXQNHXNJxgWBt9X5CSs4w1SSLWmXMsm1zRCoiqPs&fixed=in' target='_blank'> <img src={btc} style={{width:"70px"}} /></a>
    
     </Grid>
     <Grid item lg={2.4} md={2.4} xs={6} sm={6} className='moveelement_center popout'>
 <a href='https://t.co/a4keBleBlZ' target='_blank'> <FaTelegramPlane style={{fontSize:"50px" , color:"#fff" }} /></a>
    
     </Grid>
     <Grid item lg={2.4} md={2.4} xs={6} sm={6} className='moveelement_center popout'>
 <a href='https://twitter.com/blockbuster_sol' target='_blank'> <FaXTwitter style={{fontSize:"50px" , color:"#fff" }} /></a>
    
     </Grid>
     
     <Grid item lg={2.4} md={2.4} xs={6} sm={6} className='moveelement_center popout'>
<a href='https://birdeye.so/token/ERKYpXQNHXNJxgWBt9X5CSs4w1SSLWmXMsm1zRCoiqPs?chain=solana' target='_blank'><img src={ord} style={{width:"70px"}}/></a>


     </Grid>
  

     </Grid>
 



    </div>
  )
}

export default Navbar