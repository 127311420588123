import React from 'react'
import { Grid } from '@mui/material'
import hero from './image/ok.png'
function Desktop() {
  return (
    <div className='container'>
   <Grid item lg={12} md={12} xs={12} sm={12} className='moveelement_center '>
     <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
<img src={hero} style={{width:"100%"}} />

     </Grid>
     <Grid container spacing={2}>
     <Grid item lg={6} md={6} xs={12} sm={12}>
     <br/>   <br/>  
     <h1 className='title 'style={{ textAlign:"center" }}>We are working on rewinding your movie.</h1>
     <br/>
<p className='under_title' style={{ textAlign:"center" }}>...but while you wait, check out all that Sling TV has to offer.</p>

<p className='under_title_ca' style={{ textAlign:"center" }}>CA : ERKYpXQNHXNJxgWBt9X5CSs4w1SSLWmXMsm1zRCoiqPs</p>
<br/>
<div style={{display:"flex" , textAlign:"center" , justifyContent:"center"}}>
     <a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=ERKYpXQNHXNJxgWBt9X5CSs4w1SSLWmXMsm1zRCoiqPs&fixed=in'>

     <button class='glowing-btn'><span class='glowing-txt'>B<span class='faulty-letter'>U</span>Y</span></button>

     </a>



      </div>
     
      <br/> <br/>

     </Grid>
   
     </Grid>
      <br/>   <br/>
      </div>
  )
}

export default Desktop